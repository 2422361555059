import "./styles.scss"

import React from "react"

import Button from "components/Button"

const CourseContent = ({ course }) => {
  const { duration, durationopt, mainImg, price, priceopt, description } =
    course

  return (
    <section className="single-course">
      <div className="container">
        <div className="single-course__wrapper">
          <div className="single-course__image">
            <img src={mainImg?.sourceUrl} alt={mainImg?.altText} />
          </div>
          <div className="single-course__main">
            <div
              className="single-course__content"
              dangerouslySetInnerHTML={{ __html: description }}
            />
            <p className="single-course__chapter">Czas oraz koszt</p>
            <div className="single-course__details">
              <div className="single-course__info">
                <img
                  src={require("assets/icons/calendar.svg").default}
                  alt=""
                />
                <p className="single-course__info-type">Czas trwania kursu</p>
                <div>
                  <p className="single-course__info-details">{duration}</p>
                  {durationopt && (
                    <p className="single-course__info-details">{durationopt}</p>
                  )}
                </div>
              </div>
              <div className="single-course__info">
                <img src={require("assets/icons/coins.svg").default} alt="" />
                <p className="single-course__info-type">Cena za kurs</p>
                <div>
                  <p className="single-course__info-details">{price} zł</p>
                  {priceopt && (
                    <p className="single-course__info-details">{priceopt} zł</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="single-course__cta">
            <p>Jesteś zainteresowany szkoleniem?</p>
            <Button type="button" className="button-dark-green" to="/kontakt/">
              Skontaktuj się z nami
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CourseContent
