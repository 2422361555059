import React from "react"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import Breadcrumbs from "components/Breadcrumbs"
import CourseContent from "page_components/single-course/CourseContent"

const SingleCourse = ({ pageContext }) => {
  const breadcrumbs_data = [
    {
      name: "Szkolenia",
      href: "/szkolenia/",
    },
    {
      name: "Szkolenia stacjonarne",
      href: "/szkolenia/szkolenia-stacjonarne/",
    },
    {
      name: pageContext.data.title,
      href: `/szkolenia/szkolenia-stacjonarne/${pageContext?.data?.slug}/`,
    },
  ]
  return (
    <Layout
      seo={{
        title: pageContext?.data?.seo?.title,
        description: pageContext?.data?.seo?.metaDesc,
      }}
    >
      <Breadcrumbs breadcrumbs_data={breadcrumbs_data} />
      <PageHeader header={pageContext?.data?.title} />
      <CourseContent course={pageContext?.data?.acfCourses} />
    </Layout>
  )
}

export default SingleCourse
